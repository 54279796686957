window.onload = function() {
    var links = document.querySelectorAll(".sidebar a");

    links.forEach(function(link) {
        link.addEventListener("click", function(event) {
            links.forEach(function(l) {
                l.classList.remove("active");
            });

            link.classList.add("active");
        });
    });
};
