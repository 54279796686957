import './scripts/anchor-to-active.js'

document.addEventListener('DOMContentLoaded', function() {
    window.addEventListener('scroll', function() {
        if (window.pageYOffset > 1) {
            // if (window.innerWidth >= 991) {
                document.querySelector('.header').classList.add('active');
            // }
        } else {
            // if (window.innerWidth >= 991) {
                document.querySelector('.header').classList.remove('active');
            // }
        }
    });

    var subtitleElements = document.querySelectorAll('.footer .subtitle');
    subtitleElements.forEach(function(subtitleElement) {
        subtitleElement.addEventListener('click', function() {
            if (window.innerWidth <= 991) {
                var parentElement = this.parentElement;
                parentElement.classList.toggle('active');

                var nextElement = this.nextElementSibling;

                slideToggle(nextElement, 300);
            }
        });
    });

    var htmlLazyLoadingElements = document.querySelectorAll('.html_lazy_loading');
    htmlLazyLoadingElements.forEach(function(lazyElement, index) {
        setTimeout(function() {
            lazyElement.classList.remove('html_lazy_loading');
        }, 100 * (index + 1));
    });

    var appCloseBtn = document.getElementById('btn-app-close');
    if (typeof appCloseBtn !== "undefined" && appCloseBtn) {
        appCloseBtn.addEventListener('click', function() {
            setCookie('show-app-download-banner', '0', 1);
            document.getElementById('app-install-banner').classList.toggle('hide');
            document.getElementById('app-install-banner-space').remove();

            var elements = document.getElementsByClassName("has-app-install-banner");
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('has-app-install-banner');
            }
        });
    }

    window?.initFaqs();

    // Sign up popup
    var elements = document.getElementsByClassName("toggle-sign-up-popup");
    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', toggleSignUpPopup, false);
    }

    // Tabs
    var triggerTabList = [].slice.call(document.querySelectorAll('.tabs .tab'));
    triggerTabList.forEach(function (triggerEl) {
        triggerEl.addEventListener('click', function (event) {
            event.preventDefault();
            const triggeredTab = document.getElementById(triggerEl.getAttribute('content-id'));
            if (triggeredTab) {
                const TabsContainer = triggerEl.closest(".tabs");
                const Tabs = [].slice.call(TabsContainer.querySelectorAll('.tab'));
                Tabs.forEach(function (tabsEl) {
                    tabsEl.classList.remove("active");
                });
                triggerEl.classList.add("active");

                var TabList = [].slice.call(triggeredTab.parentNode.querySelectorAll('.tab-pane'));
                TabList.forEach(function (tabListEl) {
                    tabListEl.classList.remove("active", "show");
                });

                triggeredTab.classList.add("active", "show");
            }
        });
    });
});

window.initFaqs = function () {
    var faqs = document.querySelectorAll('.faqs');
    faqs.forEach(function (faq) {
        if (faq.classList.contains('init')) {
            return
        }
        faq.classList.add('init')
        var elements = faq.querySelectorAll('.question');
        elements.forEach(function(element) {
            if (element.closest('.faqs'))
                element.addEventListener('click', function(event) {
                    const answer = event.target.nextElementSibling;
                    if (answer.classList.contains('active')) {
                        answer.classList.remove('active');
                        event.target.classList.remove('active')
                    } else {
                        answer.classList.add('active');
                        event.target.classList.add('active')
                    }
                });
        });
    })
}

function slideToggle(element, duration) {
    console.log(element.scrollHeight)
    if( element.offsetHeight == 0) {
        var startHeight = 0;
        var endHeight = element.scrollHeight;
    } else {
        var startHeight =  element.offsetHeight;
        var endHeight = 0;
    }

    var startTime = null;

    function animate(timestamp) {
        if (!startTime) startTime = timestamp;
        var progress = timestamp - startTime;
        var percentage = Math.min(progress / duration, 1);
        var height = startHeight + percentage * (endHeight - startHeight);

        element.style.height = height + 'px';

        if(height === 0){
            element.style.height = null;
        }

        if (percentage < 1) {
            requestAnimationFrame(animate);
        }
    }

    requestAnimationFrame(animate);
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function toggleSignUpPopup(event) {
    if (event) {
        event.preventDefault();
    }
    var signUpPopup = document.querySelector('.sign-up-options-popup');
    if (signUpPopup.classList.contains('active')) {
        signUpPopup.classList.remove('active');
        document.removeEventListener('click', signUpPopupOutsideClick, true)
    } else {
        signUpPopup.classList.add('active');
        document.addEventListener('click', signUpPopupOutsideClick, true)
    }
}

function signUpPopupOutsideClick(event) {
    var signUpPopup = document.querySelector('.sign-up-options-popup');
    let isClickInside = signUpPopup.contains(event.target)

    var elements = document.getElementsByClassName("toggle-sign-up-popup");
    for (var i = 0; i < elements.length; i++) {
        isClickInside = isClickInside || elements[i].contains(event.target)
    }

    if (!isClickInside) {
        if (signUpPopup.classList.contains('active')) {
            signUpPopup.classList.remove('active');
        }
    }
}

window.trackLinkEvent = function (link, eventName, properties = {}) {
    const href = link.href;
    return true;
    // // if (!window?.gtag) {
    // //     return true;
    // // }
    //
    // window.gtag('event', eventName, properties, () => {
    //     window.location.href = href;
    // });



    //
    // // window.analytics.track(eventName, properties, {}, () => {
    // //     window.location.href = href;
    // // });
    //
    // return false;
}

window.trackEvent = function (eventName, properties = {}) {
    // window.analytics.track(eventName, properties);
    return false;
}


if( document.querySelector('.account-icon') ) {
    document.querySelector('.account-icon').addEventListener('click', function () {
        document.querySelector('.account-menu').classList.toggle('active');
        document.querySelector('.account-icon svg').classList.toggle('active');

        document.body.classList.add('lock');
        document.querySelectorAll('.account-menu-item .back').forEach(function (element) {
            element.addEventListener('click', function () {
                document.querySelector('.account-menu').classList.remove('active');
                document.body.classList.remove('lock');
            });
        });
    });
}


// mobile menu
const mobileMenuHamburger = document.querySelector('.header__mobile_hamburger')
console.log(mobileMenuHamburger);
if (mobileMenuHamburger) {
    const mobileMenuModal = document.querySelector('.header__mobile_modal')
    const firstModalCloset = document.querySelector('.header__mobile_modal_close')
    const modalCard = document.querySelector('.modal__card')
    const modalLinks = document.querySelectorAll('.modal__content a')

    mobileMenuHamburger.addEventListener('click', () => {
        mobileMenuModal.classList.remove('hiden');
        setTimeout(() => {
            modalCard.classList.add('open')
        }, 50);

        document.querySelector('body').style.overflow = 'hidden';
    })
    firstModalCloset.addEventListener('click', () => {
        setTimeout(() => {
            mobileMenuModal.classList.add('hiden');
        }, 500);
        modalCard.classList.remove('open')


        document.querySelector('body').style.overflow = 'auto';
    })
    modalLinks.forEach((link) => {
        link.addEventListener('click', () => {
            setTimeout(() => {
                mobileMenuModal.classList.add('hiden');
            }, 500);
            modalCard.classList.remove('open')


            document.querySelector('body').style.overflow = 'auto';
        })
    });

    mobileMenuModal.addEventListener('click', () => {
        setTimeout(() => {
            mobileMenuModal.classList.add('hiden');
        }, 500);

        modalCard.classList.remove('open')

        document.querySelector('body').style.overflow = 'auto';
    })

    mobileMenuModal.querySelector('.modal__card').addEventListener('click', (e) => {
        e.stopPropagation()
    })
}
